var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{staticClass:"form-horizontal form-horizontal-text-right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4 required"},[_vm._v(_vm._s(_vm.$t("customerLoanRequest.loanType")))]),_c('div',{staticClass:"col-md-7"},[_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.model.loan_type_id),expression:"model.loan_type_id",modifiers:{"number":true}}],staticClass:"form-select form-select-sm",class:{
                                'is-invalid':
                                    _vm.errors.has('loan_type_id') ||
                                    _vm.$v.model.loan_type_id.$error
                            },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.model, "loan_type_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.onChangeLoanType]}},[_c('option',{attrs:{"selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.loanTypes),function(option,index){return _c('option',{key:index,domProps:{"value":option.loan_type_id}},[_vm._v(" "+_vm._s(_vm.locale == "kh" ? option.loan_type_kh : option.loan_type_en)+" ")])})],2),(_vm.loanTypeIdErrors.length > 0)?_c('span',{staticClass:"validate text-danger"},[_vm._v(_vm._s(_vm.loanTypeIdErrors[0]))]):_vm._e(),(_vm.errors.has('loan_type_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("loan_type_id"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4 required"},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.requestDate"))+" ")]),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"},[_c('DatePicker',{class:{
                                    'ivu-form-item-error':
                                        _vm.errors.has('requested_date') ||
                                        _vm.$v.model.requested_date.$error
                                },attrs:{"value":_vm.model.requested_date,"placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy"},on:{"on-change":_vm.onChangeRequestDate}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.group_id),expression:"model.group_id"}],ref:"group_id",staticClass:"form-control form-control-sm",class:{
                                    'is-invalid': _vm.errors.has('group_id')
                                },attrs:{"placeholder":_vm.isGroupLoan
                                        ? _vm.$t(
                                              'customerLoanRequest.groupNumber'
                                          )
                                        : _vm.$t(
                                              'customerLoanRequest.groupNumberOptional'
                                          ),"disabled":!_vm.isGroupLoan},domProps:{"value":(_vm.model.group_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "group_id", $event.target.value)}}})],1),(_vm.requestedDateErrors.length > 0)?_c('span',{staticClass:"validate text-danger"},[_vm._v(_vm._s(_vm.requestedDateErrors[0]))]):_vm._e(),(_vm.errors.has('requested_date'))?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first("requested_date"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4 required"},[_vm._v(_vm._s(_vm.$t("customerLoanRequest.currency")))]),_c('div',{staticClass:"col-md-7 tw-whitespace-nowrap"},[_c('RadioGroup',{attrs:{"size":"small"},model:{value:(_vm.model.currency_id),callback:function ($$v) {_vm.$set(_vm.model, "currency_id", $$v)},expression:"model.currency_id"}},_vm._l((_vm.currencies),function(c,index){return _c('Radio',{key:index,staticClass:"mt-1",attrs:{"label":c.currency_id,"border":""}},[_vm._v(" "+_vm._s(c.currency_code)+" ")])}),1),(_vm.currencyIdErrors.length > 0)?_c('span',{staticClass:"validate text-danger"},[_vm._v(_vm._s(_vm.currencyIdErrors[0]))]):_vm._e(),(_vm.errors.has('currency_id'))?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first("currency_id"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row",class:{
                        'mb-3': !_vm.loanCurrency
                    }},[_c('label',{staticClass:"form-label col-form-label col-md-4 required"},[_vm._v(_vm._s(_vm.$t("customerLoanRequest.loanAmount")))]),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"},[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"request_amount",class:{
                                        'ivu-form-item-error':
                                            _vm.errors.has('request_amount') ||
                                            _vm.$v.model.request_amount.$error
                                    },staticStyle:{"width":"110%"},attrs:{"placeholder":_vm.$t(
                                            'customerLoanRequest.enterNumber'
                                        )},model:{value:(_vm.model.request_amount),callback:function ($$v) {_vm.$set(_vm.model, "request_amount", _vm._n($$v))},expression:"model.request_amount"}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.request_amount ? _vm.formatNumber( _vm.model.request_amount, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1),_c('label',{staticClass:"form-label col-form-label required"},[_c('small',{staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(_vm.$t("customerLoanRequest.interestRate")))])]),_c('InputNumber',{class:{
                                    'ivu-form-item-error': _vm.errors.has(
                                        'request_interest_rate'
                                    )
                                },staticStyle:{"width":"30%"},attrs:{"max":100,"formatter":function (value) { return (value + "%"); },"parser":function (value) { return value.replace('%', ''); },"placeholder":_vm.$t('customerLoanRequest.interestRate'),"disabled":""},model:{value:(_vm.model.request_interest_rate),callback:function ($$v) {_vm.$set(_vm.model, "request_interest_rate", $$v)},expression:"model.request_interest_rate"}})],1),(_vm.requestAmountErrors.length > 0)?_c('span',{staticClass:"validate text-danger"},[_vm._v(_vm._s(_vm.requestAmountErrors[0]))]):_vm._e(),(_vm.errors.has('request_interest_rate'))?_c('div',{staticClass:"text-danger tw-text-right"},[_vm._v(" "+_vm._s(_vm.errors.first("request_interest_rate"))+" ")]):_vm._e()])]),(_vm.loanCurrency)?_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"}),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"},[_c('span',{staticClass:"badge bg-success rounded-pill"},[_vm._v("Min amount "+_vm._s(_vm.formatNumber( _vm.loanCurrency.min_size, _vm.model.currency_id ))+" Max amount "+_vm._s(_vm.formatNumber( _vm.loanCurrency.max_size, _vm.model.currency_id )))]),_c('span',{staticClass:"badge bg-blue rounded-pill"},[_vm._v("Min rate "+_vm._s(_vm.loanCurrency.min_rate)+" Max rate "+_vm._s(_vm.loanCurrency.max_rate))])])])]):_vm._e(),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4 required"},[_vm._v(_vm._s(_vm.$t("customerLoanRequest.co")))]),_c('div',{staticClass:"col-md-7"},[_c('Select',{class:{
                                'ivu-form-item-error':
                                    _vm.errors.has('emp_co_id') ||
                                    _vm.$v.model.emp_co_id.$error
                            },attrs:{"filterable":"","clearable":"","remote-method":_vm.loadEmployee,"loading":_vm.employeeLoading,"placeholder":_vm.$t('customerLoanRequest.searchEmployee')},model:{value:(_vm.model.emp_co_id),callback:function ($$v) {_vm.$set(_vm.model, "emp_co_id", $$v)},expression:"model.emp_co_id"}},_vm._l((_vm.employees),function(option,index){return _c('Option',{key:index,attrs:{"value":option.employee_id}},[_vm._v(" "+_vm._s(_vm.locale == "kh" ? option.employee_name_kh : option.employee_name_en)+" ")])}),1),(_vm.empCoIdErrors.length > 0)?_c('span',{staticClass:"validate text-danger"},[_vm._v(_vm._s(_vm.empCoIdErrors[0]))]):_vm._e(),(_vm.errors.has('emp_co_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("emp_co_id"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"form-label col-form-label col-md-4 required"},[_vm._v(_vm._s(_vm.$t("customerLoanRequest.bmName")))]),_c('div',{staticClass:"col-md-7"},[_c('Select',{class:{
                                'ivu-form-item-error':
                                    _vm.errors.has('emp_bm_id') ||
                                    _vm.$v.model.emp_bm_id.$error
                            },attrs:{"filterable":"","clearable":"","remote-method":_vm.loadBmEmployee,"loading":_vm.bmEmployeeLoading,"placeholder":_vm.$t('customerLoanRequest.searchBm')},model:{value:(_vm.model.emp_bm_id),callback:function ($$v) {_vm.$set(_vm.model, "emp_bm_id", $$v)},expression:"model.emp_bm_id"}},_vm._l((_vm.bmEmployees),function(option,index){return _c('Option',{key:index,attrs:{"value":option.employee_id}},[_vm._v(" "+_vm._s(_vm.locale == "kh" ? option.employee_name_kh : option.employee_name_en)+" ")])}),1),(_vm.empBmIdErrors.length > 0)?_c('span',{staticClass:"validate text-danger"},[_vm._v(_vm._s(_vm.empBmIdErrors[0]))]):_vm._e(),(_vm.errors.has('emp_co_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("emp_co_id"))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4 required"},[_vm._v(_vm._s(_vm.$t("customerLoanRequest.purpose")))]),_c('div',{staticClass:"col-md-7"},[_c('Select',{class:{
                                'ivu-form-item-error':
                                    _vm.errors.has('purpose_id') ||
                                    _vm.$v.model.purpose_id.$error
                            },staticStyle:{"width":"200px"},attrs:{"filterable":"","clearable":""},model:{value:(_vm.model.purpose_id),callback:function ($$v) {_vm.$set(_vm.model, "purpose_id", $$v)},expression:"model.purpose_id"}},_vm._l((_vm.purpose),function(sector,index){return _c('OptionGroup',{key:index,attrs:{"label":sector.sector_code +
                                        '|' +
                                        (_vm.locale == 'kh'
                                            ? sector.sector_kh
                                            : sector.sector_en)}},_vm._l((sector.purposes),function(option,i){return _c('Option',{key:i,attrs:{"value":option.id}},[_vm._v(_vm._s(option.purpose_code + "-" + (_vm.locale == "kh" ? option.purpose_kh : option.purpose_en)))])}),1)}),1),(_vm.purposeIdErrors.length > 0)?_c('span',{staticClass:"validate text-danger"},[_vm._v(_vm._s(_vm.purposeIdErrors[0]))]):_vm._e(),(_vm.errors.has('purpose_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("purpose_id"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4 required"},[_vm._v(_vm._s(_vm.$t("customerLoanRequest.installmentType")))]),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"tw-flex tw-whitespace-nowrap tw-space-x-1 tw-justify-between tw-items-center"},[_c('RadioGroup',{class:{
                                    'ivu-form-item-error':
                                        _vm.errors.has(
                                            'request_installment_type_id'
                                        ) ||
                                        _vm.$v.model.request_installment_type_id
                                            .$error
                                },attrs:{"size":"small","type":"button"},model:{value:(_vm.model.request_installment_type_id),callback:function ($$v) {_vm.$set(_vm.model, "request_installment_type_id", $$v)},expression:"model.request_installment_type_id"}},_vm._l((_vm.installmentTypes),function(c,index){return _c('Radio',{key:index,staticClass:"mt-1",attrs:{"label":c.id}},[_vm._v(" "+_vm._s(c.label)+" ")])}),1)],1),(_vm.requestInstallmentTypeIdErrors.length > 0)?_c('span',{staticClass:"validate text-danger"},[_vm._v(_vm._s(_vm.requestInstallmentTypeIdErrors[0]))]):_vm._e(),(_vm.errors.has('request_installment_type_id'))?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first("request_installment_type_id"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4 required"},[_vm._v(_vm._s(_vm.$t( "customerLoanRequest.installmentTypeDuration" ))+" (T):")]),_c('div',{staticClass:"col-md-7"},[_c('InputNumber',{class:{
                                'ivu-form-item-error':
                                    _vm.errors.has('request_loan_duration') ||
                                    _vm.$v.model.request_loan_duration.$error
                            },attrs:{"min":1},model:{value:(_vm.model.request_loan_duration),callback:function ($$v) {_vm.$set(_vm.model, "request_loan_duration", _vm._n($$v))},expression:"model.request_loan_duration"}}),_c('br'),(_vm.requestLoanDurationErrors.length > 0)?_c('span',{staticClass:"validate text-danger"},[_vm._v(_vm._s(_vm.requestLoanDurationErrors[0]))]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"form-label col-form-label col-md-4"},[_vm._v(_vm._s(_vm.$t("customerLoanRequest.firstInstallmentAmount"))+" (R):")]),_c('div',{staticClass:"col-md-7"},[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"first_installment_amount",staticStyle:{"width":"107%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber')},model:{value:(
                                    _vm.model.first_installment_amount
                                ),callback:function ($$v) {_vm.$set(_vm.model, "first_installment_amount", _vm._n($$v))},expression:"\n                                    model.first_installment_amount\n                                "}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.first_installment_amount ? _vm.formatNumber( _vm.model.first_installment_amount, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1)])]),_c('div',{staticClass:"col-md-1"})])])])}
var staticRenderFns = []

export { render, staticRenderFns }