var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-ui-detail"},[_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"25%"}},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.asset1"))+" ")]),_c('th',{attrs:{"width":"25%"}},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.asset2"))+" ")]),_c('th',{attrs:{"width":"25%"}},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.asset3"))+" ")]),_c('th',{attrs:{"width":"25%"}},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.asset4"))+" ")])])]),_c('tbody',[_c('tr',[_c('td',[_c('Input',{attrs:{"placeholder":_vm.$t(
                                        'customerLoanRequest.nameLoanCollateral1'
                                    )},model:{value:(_vm.model.name_loan_collateral_1),callback:function ($$v) {_vm.$set(_vm.model, "name_loan_collateral_1", $$v)},expression:"model.name_loan_collateral_1"}})],1),_c('td',[_c('Input',{attrs:{"placeholder":_vm.$t(
                                        'customerLoanRequest.nameLoanCollateral2'
                                    )},model:{value:(_vm.model.name_loan_collateral_2),callback:function ($$v) {_vm.$set(_vm.model, "name_loan_collateral_2", $$v)},expression:"model.name_loan_collateral_2"}})],1),_c('td',[_c('Input',{attrs:{"placeholder":_vm.$t(
                                        'customerLoanRequest.nameLoanCollateral3'
                                    )},model:{value:(_vm.model.name_loan_collateral_3),callback:function ($$v) {_vm.$set(_vm.model, "name_loan_collateral_3", $$v)},expression:"model.name_loan_collateral_3"}})],1),_c('td',[_c('Input',{attrs:{"placeholder":_vm.$t(
                                        'customerLoanRequest.nameLoanCollateral4'
                                    )},model:{value:(_vm.model.name_loan_collateral_4),callback:function ($$v) {_vm.$set(_vm.model, "name_loan_collateral_4", $$v)},expression:"model.name_loan_collateral_4"}})],1)]),_c('tr',[_c('td',[_c('Input',{attrs:{"type":"number","placeholder":_vm.$t(
                                        'customerLoanRequest.priceLoanCollateral1'
                                    )},on:{"on-change":_vm.onCalculatePriceLoanCollateral},model:{value:(_vm.model.price_loan_collateral_1),callback:function ($$v) {_vm.$set(_vm.model, "price_loan_collateral_1", _vm._n($$v))},expression:"model.price_loan_collateral_1"}})],1),_c('td',[_c('Input',{attrs:{"type":"number","placeholder":_vm.$t(
                                        'customerLoanRequest.priceLoanCollateral2'
                                    )},on:{"on-change":_vm.onCalculatePriceLoanCollateral},model:{value:(_vm.model.price_loan_collateral_2),callback:function ($$v) {_vm.$set(_vm.model, "price_loan_collateral_2", _vm._n($$v))},expression:"model.price_loan_collateral_2"}})],1),_c('td',[_c('Input',{attrs:{"type":"number","placeholder":_vm.$t(
                                        'customerLoanRequest.priceLoanCollateral3'
                                    )},on:{"on-change":_vm.onCalculatePriceLoanCollateral},model:{value:(_vm.model.price_loan_collateral_3),callback:function ($$v) {_vm.$set(_vm.model, "price_loan_collateral_3", _vm._n($$v))},expression:"model.price_loan_collateral_3"}})],1),_c('td',[_c('Input',{attrs:{"type":"number","placeholder":_vm.$t(
                                        'customerLoanRequest.priceLoanCollateral4'
                                    )},on:{"on-change":_vm.onCalculatePriceLoanCollateral},model:{value:(_vm.model.price_loan_collateral_4),callback:function ($$v) {_vm.$set(_vm.model, "price_loan_collateral_4", _vm._n($$v))},expression:"model.price_loan_collateral_4"}})],1)]),_c('tr',[_c('td',[_c('Input',{attrs:{"placeholder":_vm.$t(
                                        'customerLoanRequest.cardType1'
                                    )},model:{value:(_vm.model.card_type_1),callback:function ($$v) {_vm.$set(_vm.model, "card_type_1", $$v)},expression:"model.card_type_1"}})],1),_c('td',[_c('Input',{attrs:{"placeholder":_vm.$t(
                                        'customerLoanRequest.cardType2'
                                    )},model:{value:(_vm.model.card_type_2),callback:function ($$v) {_vm.$set(_vm.model, "card_type_2", $$v)},expression:"model.card_type_2"}})],1),_c('td',[_c('Input',{attrs:{"placeholder":_vm.$t(
                                        'customerLoanRequest.cardType3'
                                    )},model:{value:(_vm.model.card_type_3),callback:function ($$v) {_vm.$set(_vm.model, "card_type_3", $$v)},expression:"model.card_type_3"}})],1),_c('td',[_c('Input',{attrs:{"placeholder":_vm.$t(
                                        'customerLoanRequest.cardType4'
                                    )},model:{value:(_vm.model.card_type_4),callback:function ($$v) {_vm.$set(_vm.model, "card_type_4", $$v)},expression:"model.card_type_4"}})],1)]),_c('tr',[_c('td',[_c('DatePicker',{staticClass:"tw-w-full",attrs:{"value":_vm.model.date_issuance_1,"placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy"},on:{"on-change":_vm.onChangeDateIssuance1}})],1),_c('td',[_c('DatePicker',{staticClass:"tw-w-full",attrs:{"value":_vm.model.date_issuance_2,"placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy"},on:{"on-change":_vm.onChangeDateIssuance2}})],1),_c('td',[_c('DatePicker',{staticClass:"tw-w-full",attrs:{"value":_vm.model.date_issuance_3,"placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy"},on:{"on-change":_vm.onChangeDateIssuance3}})],1),_c('td',[_c('DatePicker',{staticClass:"tw-w-full",attrs:{"value":_vm.model.date_issuance_4,"placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy"},on:{"on-change":_vm.onChangeDateIssuance4}})],1)]),_c('tr',[_c('td',[_c('Input',{attrs:{"placeholder":_vm.$t(
                                        'customerLoanRequest.issuedBy1'
                                    )},model:{value:(_vm.model.issue_by_1),callback:function ($$v) {_vm.$set(_vm.model, "issue_by_1", $$v)},expression:"model.issue_by_1"}})],1),_c('td',[_c('Input',{attrs:{"placeholder":_vm.$t(
                                        'customerLoanRequest.issuedBy2'
                                    )},model:{value:(_vm.model.issue_by_2),callback:function ($$v) {_vm.$set(_vm.model, "issue_by_2", $$v)},expression:"model.issue_by_2"}})],1),_c('td',[_c('Input',{attrs:{"placeholder":_vm.$t(
                                        'customerLoanRequest.issuedBy3'
                                    )},model:{value:(_vm.model.issue_by_3),callback:function ($$v) {_vm.$set(_vm.model, "issue_by_3", $$v)},expression:"model.issue_by_3"}})],1),_c('td',[_c('Input',{attrs:{"placeholder":_vm.$t(
                                        'customerLoanRequest.issuedBy4'
                                    )},model:{value:(_vm.model.issue_by_4),callback:function ($$v) {_vm.$set(_vm.model, "issue_by_4", $$v)},expression:"model.issue_by_4"}})],1)])]),_c('thead',[_c('tr',[_c('th',{staticClass:"tw-text-right text-success tw-font-bold",attrs:{"width":"50%","colspan":"2"}},[_vm._v(" Price loan collateral compared to loans 130% (Price loan collateral ៖ Loan amount) x 100: ")]),_c('th',{attrs:{"width":"50%","colspan":"2"}},[_c('Input',{staticStyle:{"width":"100%"},attrs:{"size":"large","disabled":""},model:{value:(_vm.model.loan_collateral_amount),callback:function ($$v) {_vm.$set(_vm.model, "loan_collateral_amount", $$v)},expression:"model.loan_collateral_amount"}})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }