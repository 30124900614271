<template>
    <div>
        <div class="row">
            <div class="col-lg-7">
                <fieldset class="mt-2">
                    <legend class="text-blue">
                        {{ $t("customerLoanRequest.customerInformation") }}
                    </legend>
                    <form class="form-horizontal form-horizontal-text-right">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row mb-5">
                                    <label
                                        class="form-label col-form-label col-md-3 required"
                                    >
                                        {{
                                            $t(
                                                "customerLoanRequest.customerName"
                                            )
                                        }}
                                    </label>
                                    <div class="col-md-8">
                                        <Select
                                            v-model="model.customer_id"
                                            filterable
                                            clearable
                                            :remote-method="loadCustomer"
                                            :loading="customerLoading"
                                            :placeholder="
                                                $t(
                                                    'customerLoanRequest.searchCustomer'
                                                )
                                            "
                                            :class="{
                                                'ivu-form-item-error':
                                                    errors.has('customer_id') ||
                                                    $v.model.customer_id.$error
                                            }"
                                            size="large"
                                        >
                                            <Option
                                                v-for="(option,
                                                index) in customers"
                                                :value="option.customer_id"
                                                :key="index"
                                            >
                                                {{ option.customer_name_en }}
                                            </Option>
                                        </Select>
                                        <span
                                            class="validate text-danger"
                                            v-if="customerIdErrors.length > 0"
                                            >{{ customerIdErrors[0] }}</span
                                        >
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('customer_id')"
                                        >
                                            {{ errors.first("customer_id") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <label
                                        class="form-label col-form-label col-md-3"
                                        >{{
                                            $t(
                                                "customerLoanRequest.customerCode"
                                            )
                                        }}:</label
                                    >
                                    <div class="col-md-8">
                                        <div
                                            class="tw-flex tw-whitespace-nowrap tw-space-x-1 tw-justify-between tw-items-center"
                                        >
                                            <div class="tw-font-bold">
                                                {{
                                                    selectedCustomer
                                                        ? selectedCustomer.customer_code
                                                        : "-"
                                                }}
                                            </div>
                                            <div>
                                                <RadioGroup
                                                    v-model="model.is_new_cycle"
                                                    size="small"
                                                    type="button"
                                                >
                                                    <Radio
                                                        class="mt-1"
                                                        :label="c.val"
                                                        v-for="(c,
                                                        index) in customerTypes"
                                                        :key="index"
                                                    >
                                                        {{ c.label }}
                                                    </Radio>
                                                </RadioGroup>
                                                <br />
                                                <span
                                                    class="validate text-danger"
                                                    v-if="
                                                        isNewCycleErrors.length >
                                                            0
                                                    "
                                                    >{{
                                                        isNewCycleErrors[0]
                                                    }}</span
                                                >
                                            </div>
                                            <div>
                                                <label
                                                    class="form-label col-form-label"
                                                    >{{
                                                        $t(
                                                            "customerLoanRequest.numberOfCycle"
                                                        )
                                                    }}
                                                </label>
                                            </div>
                                            <div>
                                                <InputNumber
                                                    v-model.number="
                                                        model.cycle_number
                                                    "
                                                    :min="1"
                                                ></InputNumber>
                                                <br />
                                                <span
                                                    class="validate text-danger"
                                                    v-if="
                                                        cycleNumberErrors.length >
                                                            0
                                                    "
                                                    >{{
                                                        cycleNumberErrors[0]
                                                    }}</span
                                                >
                                            </div>
                                        </div>
                                        <div
                                            class="text-danger"
                                            v-if="errors.has('is_new_cycle')"
                                        >
                                            {{ errors.first("is_new_cycle") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <label
                                        class="form-label col-form-label col-md-3"
                                        >{{
                                            $t("customerLoanRequest.nameKh")
                                        }}:</label
                                    >
                                    <div class="col-md-8">
                                        <div
                                            class="tw-grid tw-grid-cols-3 tw-gap-3 tw-items-center"
                                        >
                                            <div
                                                class="tw-font-bold tw-text-base"
                                            >
                                                {{
                                                    selectedCustomer
                                                        ? selectedCustomer.customer_name_kh
                                                        : "-"
                                                }}
                                            </div>
                                            <div
                                                class="form-label col-form-label"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.nameEn"
                                                    )
                                                }}:
                                            </div>
                                            <div
                                                class="tw-font-bold tw-text-right"
                                            >
                                                {{
                                                    selectedCustomer
                                                        ? selectedCustomer.customer_name_en
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <label
                                        class="form-label col-form-label col-md-3"
                                        >{{
                                            $t("customerLoanRequest.sex")
                                        }}:</label
                                    >
                                    <div class="col-md-8">
                                        <div
                                            class="tw-grid tw-grid-cols-3 tw-gap-3 tw-items-center"
                                        >
                                            <div class="tw-font-bold">
                                                {{
                                                    selectedCustomer
                                                        ? selectedCustomer.gender
                                                        : "-"
                                                }}
                                            </div>
                                            <div
                                                class="form-label col-form-label"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.dateOfBirth"
                                                    )
                                                }}:
                                            </div>
                                            <div
                                                class="tw-font-bold tw-text-right"
                                            >
                                                {{
                                                    selectedCustomer
                                                        ? selectedCustomer.date_of_birth
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <label
                                        class="form-label col-form-label col-md-3"
                                        >{{
                                            $t(
                                                "customerLoanRequest.documentType"
                                            )
                                        }}:</label
                                    >
                                    <div class="col-md-8">
                                        <div
                                            class="tw-grid tw-grid-cols-3 tw-gap-3 tw-items-center"
                                        >
                                            <div class="tw-font-bold">
                                                {{
                                                    selectedCustomer
                                                        ? selectedCustomer.document_type
                                                            ? locale == "kh"
                                                                ? selectedCustomer
                                                                      .document_type
                                                                      .doc_type_kh
                                                                : selectedCustomer
                                                                      .document_type
                                                                      .doc_type_en
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                            <div
                                                class="form-label col-form-label"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.nidOrpassport"
                                                    )
                                                }}:
                                            </div>
                                            <div
                                                class="tw-font-bold tw-text-right"
                                            >
                                                {{
                                                    selectedCustomer
                                                        ? selectedCustomer.ssn_no
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <label
                                        class="form-label col-form-label col-md-3"
                                        >{{
                                            $t(
                                                "customerLoanRequest.occupation"
                                            )
                                        }}:</label
                                    >
                                    <div class="col-md-8">
                                        <div
                                            class="tw-grid tw-grid-cols-3 tw-gap-3 tw-items-center"
                                        >
                                            <div class="tw-font-bold">
                                                {{
                                                    selectedCustomer
                                                        ? selectedCustomer.cus_occupation
                                                            ? selectedCustomer
                                                                  .cus_occupation
                                                                  .occupation_code +
                                                              "|" +
                                                              (locale == "kh"
                                                                  ? selectedCustomer
                                                                        .cus_occupation
                                                                        .occupation_kh
                                                                  : selectedCustomer
                                                                        .cus_occupation
                                                                        .occupation_en)
                                                            : "-"
                                                        : "-"
                                                }}
                                            </div>
                                            <div
                                                class="form-label col-form-label"
                                            >
                                                {{
                                                    $t(
                                                        "customerLoanRequest.contactNo"
                                                    )
                                                }}:
                                            </div>
                                            <div
                                                class="tw-font-bold tw-text-right"
                                            >
                                                {{
                                                    selectedCustomer
                                                        ? selectedCustomer.contact_no
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <label
                                        class="form-label col-form-label col-md-3"
                                        >{{
                                            $t("customerLoanRequest.address")
                                        }}:</label
                                    >
                                    <div class="col-md-8">
                                        <div class="tw-items-center">
                                            <div
                                                class="tw-text-left tw-font-bold tw-pt-2"
                                            >
                                                <div v-if="selectedCustomer">
                                                    <span v-if="locale === 'en'"
                                                        >{{
                                                            selectedCustomer
                                                                ? "House # " +
                                                                  selectedCustomer.house_no +
                                                                  ", "
                                                                : "-"
                                                        }}
                                                        {{
                                                            selectedCustomer
                                                                ? "Street # " +
                                                                  selectedCustomer.street_no +
                                                                  ", "
                                                                : "-"
                                                        }}
                                                        {{
                                                            selectedCustomer.village
                                                                ? selectedCustomer
                                                                      .village
                                                                      .name_en
                                                                    ? selectedCustomer
                                                                          .village
                                                                          .name_en +
                                                                      ", "
                                                                    : ""
                                                                : ""
                                                        }}
                                                        {{
                                                            selectedCustomer.commune
                                                                ? selectedCustomer
                                                                      .commune
                                                                      .name_en
                                                                    ? selectedCustomer
                                                                          .commune
                                                                          .name_en +
                                                                      ", "
                                                                    : ""
                                                                : ""
                                                        }}
                                                        {{
                                                            selectedCustomer.district
                                                                ? selectedCustomer
                                                                      .district
                                                                      .name_en
                                                                    ? selectedCustomer
                                                                          .district
                                                                          .name_en +
                                                                      ", "
                                                                    : ""
                                                                : ""
                                                        }}
                                                        {{
                                                            selectedCustomer.province
                                                                ? selectedCustomer
                                                                      .province
                                                                      .name_en
                                                                    ? selectedCustomer
                                                                          .province
                                                                          .name_en +
                                                                      "."
                                                                    : "-"
                                                                : "-"
                                                        }}
                                                    </span>
                                                    <span v-else>
                                                        {{
                                                            selectedCustomer
                                                                ? "លេខផ្ទះ # " +
                                                                  selectedCustomer.house_no +
                                                                  ", "
                                                                : "-"
                                                        }}
                                                        {{
                                                            selectedCustomer
                                                                ? "លេខផ្លូវ # " +
                                                                  selectedCustomer.street_no +
                                                                  ", "
                                                                : "-"
                                                        }}
                                                        {{
                                                            selectedCustomer.village
                                                                ? selectedCustomer
                                                                      .village
                                                                      .name_kh
                                                                    ? selectedCustomer
                                                                          .village
                                                                          .name_kh +
                                                                      ", "
                                                                    : ""
                                                                : ""
                                                        }}
                                                        {{
                                                            selectedCustomer.commune
                                                                ? selectedCustomer
                                                                      .commune
                                                                      .name_kh
                                                                    ? selectedCustomer
                                                                          .commune
                                                                          .name_kh +
                                                                      ", "
                                                                    : ""
                                                                : ""
                                                        }}
                                                        {{
                                                            selectedCustomer.district
                                                                ? selectedCustomer
                                                                      .district
                                                                      .name_kh
                                                                    ? selectedCustomer
                                                                          .district
                                                                          .name_kh +
                                                                      ", "
                                                                    : ""
                                                                : ""
                                                        }}
                                                        {{
                                                            selectedCustomer.province
                                                                ? selectedCustomer
                                                                      .province
                                                                      .name_kh
                                                                    ? selectedCustomer
                                                                          .province
                                                                          .name_kh +
                                                                      "."
                                                                    : "-"
                                                                : "-"
                                                        }}
                                                    </span>
                                                </div>
                                                <div v-else>-</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </fieldset>
            </div>
            <div class="col-lg-5">
                <fieldset class="mt-2">
                    <legend class="text-blue">
                        {{ $t("customerLoanRequest.spouseInformation") }}
                    </legend>
                    <form class="form-horizontal form-horizontal-text-right">
                        <div class="row mb-2">
                            <label class="form-label col-form-label col-md-3"
                                >{{ $t("customerLoanRequest.nameKh") }}:</label
                            >
                            <div class="col-md-8">
                                <div
                                    class="tw-grid tw-grid-cols-3 tw-gap-3 tw-items-center"
                                >
                                    <div class="tw-font-bold tw-text-base">
                                        {{
                                            selectedCustomer
                                                ? selectedCustomer.spouse_name_kh
                                                : "-"
                                        }}
                                    </div>
                                    <div class="form-label col-form-label">
                                        {{ $t("customerLoanRequest.nameEn") }}:
                                    </div>
                                    <div class="tw-font-bold tw-text-right">
                                        {{
                                            selectedCustomer
                                                ? selectedCustomer.spouse_name_en
                                                : "-"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label class="form-label col-form-label col-md-3"
                                >{{ $t("customerLoanRequest.sex") }}:</label
                            >
                            <div class="col-md-8">
                                <div
                                    class="tw-grid tw-grid-cols-3 tw-gap-3 tw-items-center"
                                >
                                    <div class="tw-font-bold">
                                        {{
                                            selectedCustomer
                                                ? selectedCustomer.sp_gender
                                                : "-"
                                        }}
                                    </div>
                                    <div class="form-label col-form-label">
                                        {{
                                            $t(
                                                "customerLoanRequest.dateOfBirth"
                                            )
                                        }}:
                                    </div>
                                    <div class="tw-font-bold tw-text-right">
                                        {{
                                            selectedCustomer
                                                ? selectedCustomer.sp_date_of_birth
                                                : "-"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label class="form-label col-form-label col-md-3"
                                >{{
                                    $t("customerLoanRequest.documentType")
                                }}:</label
                            >
                            <div class="col-md-8">
                                <div
                                    class="tw-grid tw-grid-cols-3 tw-gap-3 tw-items-center"
                                >
                                    <div class="tw-font-bold">
                                        {{
                                            selectedCustomer
                                                ? selectedCustomer.sp_document_type
                                                    ? locale == "kh"
                                                        ? selectedCustomer
                                                              .sp_document_type
                                                              .doc_type_kh
                                                        : selectedCustomer
                                                              .sp_document_type
                                                              .doc_type_en
                                                    : "-"
                                                : "-"
                                        }}
                                    </div>
                                    <div class="form-label col-form-label">
                                        {{
                                            $t(
                                                "customerLoanRequest.nidOrpassport"
                                            )
                                        }}:
                                    </div>
                                    <div class="tw-font-bold tw-text-right">
                                        {{
                                            selectedCustomer
                                                ? selectedCustomer.sp_ssn_no
                                                : "-"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label class="form-label col-form-label col-md-3"
                                >{{
                                    $t("customerLoanRequest.occupation")
                                }}:</label
                            >
                            <div class="col-md-8">
                                <div
                                    class="tw-grid tw-grid-cols-3 tw-gap-3 tw-items-center"
                                >
                                    <div class="tw-font-bold">
                                        {{
                                            selectedCustomer
                                                ? selectedCustomer.spouse_occupation
                                                    ? selectedCustomer
                                                          .spouse_occupation
                                                          .occupation_code +
                                                      "|" +
                                                      (locale == "kh"
                                                          ? selectedCustomer
                                                                .spouse_occupation
                                                                .occupation_kh
                                                          : selectedCustomer
                                                                .spouse_occupation
                                                                .occupation_en)
                                                    : "-"
                                                : "-"
                                        }}
                                    </div>
                                    <div class="form-label col-form-label">
                                        {{
                                            $t("customerLoanRequest.contactNo")
                                        }}:
                                    </div>
                                    <div class="tw-font-bold tw-text-right">
                                        {{
                                            selectedCustomer
                                                ? selectedCustomer.sp_contact_no
                                                : "-"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label class="form-label col-form-label col-md-3"
                                >{{ $t("customerLoanRequest.address") }}:</label
                            >
                            <div class="col-md-8">
                                <div class="tw-items-center">
                                    <div
                                        class="tw-text-left tw-font-bold tw-pt-2"
                                    >
                                        <div v-if="selectedCustomer">
                                            <span v-if="locale === 'en'"
                                                >{{
                                                    selectedCustomer
                                                        ? "House # " +
                                                          selectedCustomer.house_no +
                                                          ", "
                                                        : "-"
                                                }}
                                                {{
                                                    selectedCustomer
                                                        ? "Street # " +
                                                          selectedCustomer.street_no +
                                                          ", "
                                                        : "-"
                                                }}
                                                {{
                                                    selectedCustomer.village
                                                        ? selectedCustomer
                                                              .village.name_en
                                                            ? selectedCustomer
                                                                  .village
                                                                  .name_en +
                                                              ", "
                                                            : ""
                                                        : ""
                                                }}
                                                {{
                                                    selectedCustomer.commune
                                                        ? selectedCustomer
                                                              .commune.name_en
                                                            ? selectedCustomer
                                                                  .commune
                                                                  .name_en +
                                                              ", "
                                                            : ""
                                                        : ""
                                                }}
                                                {{
                                                    selectedCustomer.district
                                                        ? selectedCustomer
                                                              .district.name_en
                                                            ? selectedCustomer
                                                                  .district
                                                                  .name_en +
                                                              ", "
                                                            : ""
                                                        : ""
                                                }}
                                                {{
                                                    selectedCustomer.province
                                                        ? selectedCustomer
                                                              .province.name_en
                                                            ? selectedCustomer
                                                                  .province
                                                                  .name_en + "."
                                                            : "-"
                                                        : "-"
                                                }}
                                            </span>
                                            <span v-else>
                                                {{
                                                    selectedCustomer
                                                        ? "លេខផ្ទះ # " +
                                                          selectedCustomer.sp_house +
                                                          ", "
                                                        : "-"
                                                }}
                                                {{
                                                    selectedCustomer
                                                        ? "លេខផ្លូវ # " +
                                                          selectedCustomer.sp_street +
                                                          ", "
                                                        : "-"
                                                }}
                                                {{
                                                    selectedCustomer.sp_village
                                                        ? selectedCustomer
                                                              .sp_village
                                                              .name_kh
                                                            ? selectedCustomer
                                                                  .sp_village
                                                                  .name_kh +
                                                              ", "
                                                            : ""
                                                        : ""
                                                }}
                                                {{
                                                    selectedCustomer.sp_commune
                                                        ? selectedCustomer
                                                              .sp_commune
                                                              .name_kh
                                                            ? selectedCustomer
                                                                  .sp_commune
                                                                  .name_kh +
                                                              ", "
                                                            : ""
                                                        : ""
                                                }}
                                                {{
                                                    selectedCustomer.sp_district
                                                        ? selectedCustomer
                                                              .sp_district
                                                              .name_kh
                                                            ? selectedCustomer
                                                                  .sp_district
                                                                  .name_kh +
                                                              ", "
                                                            : ""
                                                        : ""
                                                }}
                                                {{
                                                    selectedCustomer.sp_province
                                                        ? selectedCustomer
                                                              .sp_province
                                                              .name_kh
                                                            ? selectedCustomer
                                                                  .sp_province
                                                                  .name_kh + "."
                                                            : "-"
                                                        : "-"
                                                }}
                                            </span>
                                        </div>
                                        <div v-else>-</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </fieldset>
                <fieldset class="mt-3">
                    <legend class="text-blue">
                        {{ $t("customerLoanRequest.guarantorInformation") }}
                    </legend>
                    <form class="form-horizontal form-horizontal-text-right">
                        <div class="row mb-2">
                            <label class="form-label col-form-label col-md-3"
                                >{{ $t("customerLoanRequest.nameKh") }}:</label
                            >
                            <div class="col-md-8">
                                <div
                                    class="tw-grid tw-grid-cols-3 tw-gap-3 tw-items-center"
                                >
                                    <div class="tw-font-bold tw-text-base">
                                        {{
                                            selectedCustomer
                                                ? selectedCustomer.guarantor_name_kh
                                                : "-"
                                        }}
                                    </div>
                                    <div class="form-label col-form-label">
                                        {{ $t("customerLoanRequest.nameEn") }}:
                                    </div>
                                    <div class="tw-font-bold tw-text-right">
                                        {{
                                            selectedCustomer
                                                ? selectedCustomer.guarantor_name_en
                                                : "-"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <label class="form-label col-form-label col-md-3"
                                >{{
                                    $t("customerLoanRequest.relationship")
                                }}:</label
                            >
                            <div class="col-md-8">
                                <div
                                    class="tw-grid tw-grid-cols-3 tw-gap-3 tw-items-center"
                                >
                                    <div class="tw-font-bold">
                                        {{
                                            selectedCustomer
                                                ? selectedCustomer.relationship
                                                : "-"
                                        }}
                                    </div>
                                    <div class="form-label col-form-label">
                                        {{
                                            $t("customerLoanRequest.contactNo")
                                        }}:
                                    </div>
                                    <div class="tw-font-bold tw-text-right">
                                        {{
                                            selectedCustomer
                                                ? selectedCustomer.guarantor_phone
                                                : "-"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";
import { required } from "vuelidate/lib/validators";

export default {
    name: "customerInformation",
    props: ["value", "validation", "clear"],
    data() {
        return {
            model: {},
            customerLoading: false,
            errors: new Errors(),
            customerTypes: [
                {
                    val: 0,
                    label: this.$t("customerLoanRequest.old")
                },
                {
                    val: 1,
                    label: this.$t("customerLoanRequest.new")
                }
            ]
        };
    },
    created() {
        this.model = this.value;
    },
    validations: {
        model: {
            customer_id: { required },
            is_new_cycle: { required },
            cycle_number: { required }
        },
        form: ["model.customer_id", "model.is_new_cycle", "model.cycle_number"]
    },
    computed: {
        ...mapState("creditOperation/customerLoanRequest", [
            "edit_data",
            "customers"
        ]),
        selectedCustomer() {
            if (this.model.customer_id) {
                return this.customers.find(
                    c => c.customer_id == this.model.customer_id
                );
            }
            return null;
        },
        locale() {
            return this.$root.$i18n.locale;
        },
        customerIdErrors() {
            const errors = [];
            if (!this.$v.model.customer_id.$error) return errors;
            !this.$v.model.customer_id.required && errors.push("Required");
            return errors;
        },
        isNewCycleErrors() {
            const errors = [];
            if (!this.$v.model.is_new_cycle.$error) return errors;
            !this.$v.model.is_new_cycle.required && errors.push("Required");
            return errors;
        },
        cycleNumberErrors() {
            const errors = [];
            if (!this.$v.model.cycle_number.$error) return errors;
            !this.$v.model.cycle_number.required && errors.push("Required");
            return errors;
        }
    },
    methods: {
        ...mapActions("creditOperation/customerLoanRequest", ["getCustomer"]),
        loadCustomer: debounce(function(query) {
            this.customerLoading = true;
            this.getCustomer({ search: query }).finally(() => {
                this.customerLoading = false;
            });
        }, 1000),
        validateForm() {
            this.$v.form.$touch();
            var isValid = !this.$v.form.$invalid;
            return isValid;
        }
    },
    watch: {
        validation: {
            handler() {
                this.errors = new Errors(this.validation.errors);
            },
            deep: true
        }
    },
    clear: function(value) {
        if (value == true) {
            this.model = this.value;
            this.file = "";
        }
    }
};
</script>
