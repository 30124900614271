var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-ui-detail"},[_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"30%"}},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.sourceOfIncome"))+" ")]),_c('th',{attrs:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.averageIncomePerMonth"))+" ")]),_c('th',{attrs:{"width":"30%"}},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.sourceOfExpense"))+" ")]),_c('th',{attrs:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.expenses"))+" ")])])]),_c('tbody',[_c('tr',[_c('td',[_c('Input',{class:{
                                'ivu-form-item-error': _vm.errors.has(
                                    'source_of_income_1'
                                )
                            },attrs:{"placeholder":_vm.$t(
                                    'customerLoanRequest.firstSourceOfIncome'
                                )},model:{value:(_vm.model.source_of_income_1),callback:function ($$v) {_vm.$set(_vm.model, "source_of_income_1", _vm._n($$v))},expression:"model.source_of_income_1"}})],1),_c('td',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"average_imcome_1",class:{
                                    'ivu-form-item-error': _vm.errors.has(
                                        'average_imcome_1'
                                    )
                                },staticStyle:{"width":"120%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber')},on:{"on-change":_vm.onCalculateIncome},model:{value:(_vm.model.average_imcome_1),callback:function ($$v) {_vm.$set(_vm.model, "average_imcome_1", _vm._n($$v))},expression:"model.average_imcome_1"}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.average_imcome_1 ? _vm.formatNumber( _vm.model.average_imcome_1, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1),_c('td',[_c('Input',{class:{
                                'ivu-form-item-error': _vm.errors.has(
                                    'source_of_expense_1'
                                )
                            },attrs:{"placeholder":_vm.$t(
                                    'customerLoanRequest.firstSourceOfExpense'
                                )},model:{value:(_vm.model.source_of_expense_1),callback:function ($$v) {_vm.$set(_vm.model, "source_of_expense_1", _vm._n($$v))},expression:"model.source_of_expense_1"}})],1),_c('td',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"expense_1",class:{
                                    'ivu-form-item-error': _vm.errors.has(
                                        'expense_1'
                                    )
                                },staticStyle:{"width":"120%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber')},on:{"on-change":_vm.onCalculateExpenses},model:{value:(_vm.model.expense_1),callback:function ($$v) {_vm.$set(_vm.model, "expense_1", _vm._n($$v))},expression:"model.expense_1"}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.expense_1 ? _vm.formatNumber( _vm.model.expense_1, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1)]),_c('tr',[_c('td',[_c('Input',{class:{
                                'ivu-form-item-error': _vm.errors.has(
                                    'source_of_income_2'
                                )
                            },attrs:{"placeholder":_vm.$t(
                                    'customerLoanRequest.secondSourceOfIncome'
                                )},model:{value:(_vm.model.source_of_income_2),callback:function ($$v) {_vm.$set(_vm.model, "source_of_income_2", _vm._n($$v))},expression:"model.source_of_income_2"}})],1),_c('td',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"average_imcome_2",class:{
                                    'ivu-form-item-error': _vm.errors.has(
                                        'average_imcome_2'
                                    )
                                },staticStyle:{"width":"120%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber')},on:{"on-change":_vm.onCalculateIncome},model:{value:(_vm.model.average_imcome_2),callback:function ($$v) {_vm.$set(_vm.model, "average_imcome_2", _vm._n($$v))},expression:"model.average_imcome_2"}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.average_imcome_2 ? _vm.formatNumber( _vm.model.average_imcome_2, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1),_c('td',[_c('Input',{class:{
                                'ivu-form-item-error': _vm.errors.has(
                                    'source_of_expense_2'
                                )
                            },attrs:{"placeholder":_vm.$t(
                                    'customerLoanRequest.secondSourceOfExpense'
                                )},model:{value:(_vm.model.source_of_expense_2),callback:function ($$v) {_vm.$set(_vm.model, "source_of_expense_2", _vm._n($$v))},expression:"model.source_of_expense_2"}})],1),_c('td',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"expense_2",class:{
                                    'ivu-form-item-error': _vm.errors.has(
                                        'expense_2'
                                    )
                                },staticStyle:{"width":"120%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber')},on:{"on-change":_vm.onCalculateExpenses},model:{value:(_vm.model.expense_2),callback:function ($$v) {_vm.$set(_vm.model, "expense_2", _vm._n($$v))},expression:"model.expense_2"}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.expense_2 ? _vm.formatNumber( _vm.model.expense_2, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1)]),_c('tr',[_c('td',[_c('Input',{class:{
                                'ivu-form-item-error': _vm.errors.has(
                                    'source_of_income_3'
                                )
                            },attrs:{"placeholder":_vm.$t(
                                    'customerLoanRequest.thirdSourceOfIncome'
                                )},model:{value:(_vm.model.source_of_income_3),callback:function ($$v) {_vm.$set(_vm.model, "source_of_income_3", _vm._n($$v))},expression:"model.source_of_income_3"}})],1),_c('td',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"average_imcome_3",class:{
                                    'ivu-form-item-error': _vm.errors.has(
                                        'average_imcome_3'
                                    )
                                },staticStyle:{"width":"120%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber')},on:{"on-change":_vm.onCalculateIncome},model:{value:(_vm.model.average_imcome_3),callback:function ($$v) {_vm.$set(_vm.model, "average_imcome_3", _vm._n($$v))},expression:"model.average_imcome_3"}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.average_imcome_3 ? _vm.formatNumber( _vm.model.average_imcome_3, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1),_c('td',[_c('Input',{class:{
                                'ivu-form-item-error': _vm.errors.has(
                                    'source_of_expense_3'
                                )
                            },attrs:{"placeholder":_vm.$t(
                                    'customerLoanRequest.thirdSourceOfExpense'
                                )},model:{value:(_vm.model.source_of_expense_3),callback:function ($$v) {_vm.$set(_vm.model, "source_of_expense_3", _vm._n($$v))},expression:"model.source_of_expense_3"}})],1),_c('td',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"expense_3",class:{
                                    'ivu-form-item-error': _vm.errors.has(
                                        'expense_3'
                                    )
                                },staticStyle:{"width":"120%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber')},on:{"on-change":_vm.onCalculateExpenses},model:{value:(_vm.model.expense_3),callback:function ($$v) {_vm.$set(_vm.model, "expense_3", _vm._n($$v))},expression:"model.expense_3"}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.expense_3 ? _vm.formatNumber( _vm.model.expense_3, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1)]),_c('tr',[_c('td',[_c('Input',{class:{
                                'ivu-form-item-error': _vm.errors.has(
                                    'source_of_income_4'
                                )
                            },attrs:{"placeholder":_vm.$t(
                                    'customerLoanRequest.fourthSourceOfIncome'
                                )},model:{value:(_vm.model.source_of_income_4),callback:function ($$v) {_vm.$set(_vm.model, "source_of_income_4", _vm._n($$v))},expression:"model.source_of_income_4"}})],1),_c('td',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"average_imcome_4",class:{
                                    'ivu-form-item-error': _vm.errors.has(
                                        'average_imcome_4'
                                    )
                                },staticStyle:{"width":"120%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber')},on:{"on-change":_vm.onCalculateIncome},model:{value:(_vm.model.average_imcome_4),callback:function ($$v) {_vm.$set(_vm.model, "average_imcome_4", _vm._n($$v))},expression:"model.average_imcome_4"}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.average_imcome_4 ? _vm.formatNumber( _vm.model.average_imcome_4, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1),_c('td',[_c('Input',{class:{
                                'ivu-form-item-error': _vm.errors.has(
                                    'source_of_expense_4'
                                )
                            },attrs:{"placeholder":_vm.$t(
                                    'customerLoanRequest.fourthSourceOfExpense'
                                )},model:{value:(_vm.model.source_of_expense_4),callback:function ($$v) {_vm.$set(_vm.model, "source_of_expense_4", _vm._n($$v))},expression:"model.source_of_expense_4"}})],1),_c('td',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"expense_4",class:{
                                    'ivu-form-item-error': _vm.errors.has(
                                        'expense_4'
                                    )
                                },staticStyle:{"width":"120%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber')},on:{"on-change":_vm.onCalculateExpenses},model:{value:(_vm.model.expense_4),callback:function ($$v) {_vm.$set(_vm.model, "expense_4", _vm._n($$v))},expression:"model.expense_4"}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.expense_4 ? _vm.formatNumber( _vm.model.expense_4, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1)])]),_c('thead',[_c('tr',[_c('th',{staticClass:"tw-text-right text-info tw-font-bold",attrs:{"width":"30%"}},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.totalMonthlyIncome"))+" (A): ")]),_c('th',{attrs:{"width":"20%"}},[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"total_monthly_income",class:{
                                    'ivu-form-item-error': _vm.errors.has(
                                        'total_monthly_income'
                                    )
                                },staticStyle:{"width":"107%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber'),"size":"large"},model:{value:(_vm.model.total_monthly_income),callback:function ($$v) {_vm.$set(_vm.model, "total_monthly_income", _vm._n($$v))},expression:"model.total_monthly_income"}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.total_monthly_income ? _vm.formatNumber( _vm.model.total_monthly_income, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1),_c('th',{staticClass:"tw-text-right text-warning tw-font-bold",attrs:{"width":"30%"}},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.totalMonthlyExpenses"))+" (B): ")]),_c('th',{attrs:{"width":"20%"}},[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"total_monthly_expense",class:{
                                    'ivu-form-item-error': _vm.errors.has(
                                        'total_monthly_expense'
                                    )
                                },staticStyle:{"width":"107%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber'),"size":"large"},model:{value:(_vm.model.total_monthly_expense),callback:function ($$v) {_vm.$set(_vm.model, "total_monthly_expense", _vm._n($$v))},expression:"model.total_monthly_expense"}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.total_monthly_expense ? _vm.formatNumber( _vm.model.total_monthly_expense, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1)]),_c('tr',[_c('th',{staticClass:"tw-text-right text-success tw-font-bold",attrs:{"width":"50%","colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.netProfit"))+" (C = A – B): ")]),_c('th',{attrs:{"width":"50%","colspan":"2"}},[_c('Input',{staticStyle:{"width":"100%"},attrs:{"value":_vm.netProfit,"size":"large","disabled":""}})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }