var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fieldset',{staticClass:"mb-4"},[_c('legend',{staticClass:"text-blue"},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.debtsOwed"))+" ")]),_c('div',{staticClass:"table-ui-detail"},[_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"30%"}},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.institutions"))+" ")]),_c('th',{attrs:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.$t( "customerLoanRequest.loanDurationInMonth" ))+" ")]),_c('th',{attrs:{"width":"30%"}},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.monthlyPayment"))+" ")]),_c('th',{attrs:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.outstandingBalance"))+" ")])])]),_c('tbody',[_c('tr',[_c('td',[_c('Input',{class:{
                                    'ivu-form-item-error': _vm.errors.has(
                                        'mfi_owe_due_1'
                                    )
                                },attrs:{"placeholder":_vm.$t('customerLoanRequest.institutions1')},model:{value:(_vm.model.mfi_owe_due_1),callback:function ($$v) {_vm.$set(_vm.model, "mfi_owe_due_1", _vm._n($$v))},expression:"model.mfi_owe_due_1"}})],1),_c('td',[_c('InputNumber',{staticStyle:{"width":"80%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber')},model:{value:(
                                    _vm.model.mfi_owe_due_1_duration
                                ),callback:function ($$v) {_vm.$set(_vm.model, "mfi_owe_due_1_duration", _vm._n($$v))},expression:"\n                                    model.mfi_owe_due_1_duration\n                                "}})],1),_c('td',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"mfi_owe_due_1_installment",class:{
                                        'ivu-form-item-error': _vm.errors.has(
                                            'mfi_owe_due_1_installment'
                                        )
                                    },staticStyle:{"width":"103%"},attrs:{"placeholder":_vm.$t(
                                            'customerLoanRequest.enterNumber'
                                        )},on:{"on-change":_vm.onCalculateOweDue},model:{value:(
                                        _vm.model.mfi_owe_due_1_installment
                                    ),callback:function ($$v) {_vm.$set(_vm.model, "mfi_owe_due_1_installment", _vm._n($$v))},expression:"\n                                        model.mfi_owe_due_1_installment\n                                    "}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.mfi_owe_due_1_installment ? _vm.formatNumber( _vm.model.mfi_owe_due_1_installment, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1),_c('td',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"mfi_owe_due_1_outstanding",class:{
                                        'ivu-form-item-error': _vm.errors.has(
                                            'mfi_owe_due_1_outstanding'
                                        )
                                    },staticStyle:{"width":"103%"},attrs:{"placeholder":_vm.$t(
                                            'customerLoanRequest.enterNumber'
                                        )},on:{"on-change":_vm.onCalculateOutstanding},model:{value:(
                                        _vm.model.mfi_owe_due_1_outstanding
                                    ),callback:function ($$v) {_vm.$set(_vm.model, "mfi_owe_due_1_outstanding", _vm._n($$v))},expression:"\n                                        model.mfi_owe_due_1_outstanding\n                                    "}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.mfi_owe_due_1_outstanding ? _vm.formatNumber( _vm.model.mfi_owe_due_1_outstanding, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1)]),_c('tr',[_c('td',[_c('Input',{class:{
                                    'ivu-form-item-error': _vm.errors.has(
                                        'mfi_owe_due_2'
                                    )
                                },attrs:{"placeholder":_vm.$t('customerLoanRequest.institutions2')},model:{value:(_vm.model.mfi_owe_due_2),callback:function ($$v) {_vm.$set(_vm.model, "mfi_owe_due_2", _vm._n($$v))},expression:"model.mfi_owe_due_2"}})],1),_c('td',[_c('InputNumber',{staticStyle:{"width":"80%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber')},model:{value:(
                                    _vm.model.mfi_owe_due_2_duration
                                ),callback:function ($$v) {_vm.$set(_vm.model, "mfi_owe_due_2_duration", _vm._n($$v))},expression:"\n                                    model.mfi_owe_due_2_duration\n                                "}})],1),_c('td',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"mfi_owe_due_2_installment",class:{
                                        'ivu-form-item-error': _vm.errors.has(
                                            'mfi_owe_due_2_installment'
                                        )
                                    },staticStyle:{"width":"103%"},attrs:{"placeholder":_vm.$t(
                                            'customerLoanRequest.enterNumber'
                                        )},on:{"on-change":_vm.onCalculateOweDue},model:{value:(
                                        _vm.model.mfi_owe_due_2_installment
                                    ),callback:function ($$v) {_vm.$set(_vm.model, "mfi_owe_due_2_installment", _vm._n($$v))},expression:"\n                                        model.mfi_owe_due_2_installment\n                                    "}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.mfi_owe_due_2_installment ? _vm.formatNumber( _vm.model.mfi_owe_due_2_installment, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1),_c('td',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"mfi_owe_due_2_outstanding",class:{
                                        'ivu-form-item-error': _vm.errors.has(
                                            'mfi_owe_due_2_outstanding'
                                        )
                                    },staticStyle:{"width":"103%"},attrs:{"placeholder":_vm.$t(
                                            'customerLoanRequest.enterNumber'
                                        )},on:{"on-change":_vm.onCalculateOutstanding},model:{value:(
                                        _vm.model.mfi_owe_due_2_outstanding
                                    ),callback:function ($$v) {_vm.$set(_vm.model, "mfi_owe_due_2_outstanding", _vm._n($$v))},expression:"\n                                        model.mfi_owe_due_2_outstanding\n                                    "}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.mfi_owe_due_2_outstanding ? _vm.formatNumber( _vm.model.mfi_owe_due_2_outstanding, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1)]),_c('tr',[_c('td',[_c('Input',{class:{
                                    'ivu-form-item-error': _vm.errors.has(
                                        'mfi_owe_due_3'
                                    )
                                },attrs:{"placeholder":_vm.$t('customerLoanRequest.institutions3')},model:{value:(_vm.model.mfi_owe_due_3),callback:function ($$v) {_vm.$set(_vm.model, "mfi_owe_due_3", _vm._n($$v))},expression:"model.mfi_owe_due_3"}})],1),_c('td',[_c('InputNumber',{staticStyle:{"width":"80%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber')},model:{value:(
                                    _vm.model.mfi_owe_due_3_duration
                                ),callback:function ($$v) {_vm.$set(_vm.model, "mfi_owe_due_3_duration", _vm._n($$v))},expression:"\n                                    model.mfi_owe_due_3_duration\n                                "}})],1),_c('td',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"mfi_owe_due_3_installment",class:{
                                        'ivu-form-item-error': _vm.errors.has(
                                            'mfi_owe_due_3_installment'
                                        )
                                    },staticStyle:{"width":"103%"},attrs:{"placeholder":_vm.$t(
                                            'customerLoanRequest.enterNumber'
                                        )},on:{"on-change":_vm.onCalculateOweDue},model:{value:(
                                        _vm.model.mfi_owe_due_3_installment
                                    ),callback:function ($$v) {_vm.$set(_vm.model, "mfi_owe_due_3_installment", _vm._n($$v))},expression:"\n                                        model.mfi_owe_due_3_installment\n                                    "}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.mfi_owe_due_3_installment ? _vm.formatNumber( _vm.model.mfi_owe_due_3_installment, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1),_c('td',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"mfi_owe_due_3_outstanding",class:{
                                        'ivu-form-item-error': _vm.errors.has(
                                            'mfi_owe_due_3_outstanding'
                                        )
                                    },staticStyle:{"width":"103%"},attrs:{"placeholder":_vm.$t(
                                            'customerLoanRequest.enterNumber'
                                        )},on:{"on-change":_vm.onCalculateOutstanding},model:{value:(
                                        _vm.model.mfi_owe_due_3_outstanding
                                    ),callback:function ($$v) {_vm.$set(_vm.model, "mfi_owe_due_3_outstanding", _vm._n($$v))},expression:"\n                                        model.mfi_owe_due_3_outstanding\n                                    "}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.mfi_owe_due_3_outstanding ? _vm.formatNumber( _vm.model.mfi_owe_due_3_outstanding, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1)])]),_c('thead',[_c('tr',[_c('th',{staticClass:"tw-text-right text-blue tw-font-bold",attrs:{"width":"50%","colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$t( "customerLoanRequest.totalDebtToBePaidMonthly" ))+" ")]),_c('th',{attrs:{"width":"30%"}},[_vm._v(" (D): "),_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"total_owe_due_to_be_paid",class:{
                                        'ivu-form-item-error': _vm.errors.has(
                                            'total_owe_due_to_be_paid'
                                        )
                                    },staticStyle:{"width":"103%"},attrs:{"placeholder":_vm.$t(
                                            'customerLoanRequest.enterNumber'
                                        ),"size":"large"},model:{value:(
                                        _vm.model.total_owe_due_to_be_paid
                                    ),callback:function ($$v) {_vm.$set(_vm.model, "total_owe_due_to_be_paid", _vm._n($$v))},expression:"\n                                        model.total_owe_due_to_be_paid\n                                    "}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.total_owe_due_to_be_paid ? _vm.formatNumber( _vm.model.total_owe_due_to_be_paid, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1),_c('th',{attrs:{"width":"20%"}},[_vm._v(" (E): "),_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"total_outstanding",class:{
                                        'ivu-form-item-error': _vm.errors.has(
                                            'total_outstanding'
                                        )
                                    },staticStyle:{"width":"103%"},attrs:{"placeholder":_vm.$t(
                                            'customerLoanRequest.enterNumber'
                                        ),"size":"large"},model:{value:(_vm.model.total_outstanding),callback:function ($$v) {_vm.$set(_vm.model, "total_outstanding", _vm._n($$v))},expression:"model.total_outstanding"}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.total_outstanding ? _vm.formatNumber( _vm.model.total_outstanding, _vm.model.currency_id ) : _vm.$t( "customerLoanRequest.enterNumber" ))+" ")])],1)],1)])])])])]),_c('fieldset',{staticClass:"mb-2"},[_c('legend',{staticClass:"text-blue"},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.abilityToBorrowMoney"))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3"}),_c('div',{staticClass:"col-lg-9 mb-4 mt-2"},[_c('div',{staticClass:"tw-font-bold"},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.disbursalAmount"))+" = (C x T x 70%) – E ")])]),_c('div'),_c('div')]),_c('div',{staticClass:"tw-grid tw-grid-cols-4 tw-gap-4 tw-items-center mb-3"},[_c('div',{staticClass:"tw-text-right"},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.disbursalAmount"))+" ")]),_c('div',[_c('Poptip',{attrs:{"trigger":"focus"}},[_c('InputNumber',{ref:"disbursal_amount",class:{
                            'ivu-form-item-error': _vm.errors.has(
                                'disbursal_amount'
                            )
                        },staticStyle:{"width":"180%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber'),"size":"large"},model:{value:(_vm.model.disbursal_amount),callback:function ($$v) {_vm.$set(_vm.model, "disbursal_amount", _vm._n($$v))},expression:"model.disbursal_amount"}},[(_vm.model.currency_id == 1)?_c('i',{staticClass:"fas fa-dollar-sign",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 2)?_c('i',{staticClass:"far fa-registered",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e(),(_vm.model.currency_id == 3)?_c('i',{staticClass:"fas fa-bold",attrs:{"slot":"prefix"},slot:"prefix"}):_vm._e()]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.model.disbursal_amount ? _vm.formatNumber( _vm.model.disbursal_amount, _vm.model.currency_id ) : _vm.$t("customerLoanRequest.enterNumber"))+" ")])],1)],1),_c('div',{staticClass:"tw-text-left"}),_c('div')]),_c('div',{staticClass:"tw-grid tw-grid-cols-4 tw-gap-4 tw-items-center mb-2"},[_c('div',{staticClass:"tw-text-right"},[_vm._v(" "+_vm._s(_vm.$t("customerLoanRequest.installmentPossibility"))+" = ((C - D) ៖ (R x 2)) x 100 ")]),_c('div',[_c('InputNumber',{staticStyle:{"width":"100%"},attrs:{"placeholder":_vm.$t('customerLoanRequest.enterNumber'),"size":"large"},model:{value:(_vm.model.installment_possibility),callback:function ($$v) {_vm.$set(_vm.model, "installment_possibility", _vm._n($$v))},expression:"model.installment_possibility"}})],1),_vm._v(" % (>=100%) "),_c('div',{staticClass:"tw-text-left"}),_c('div')])])])}
var staticRenderFns = []

export { render, staticRenderFns }