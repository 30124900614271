<template>
    <div>
        <div class="table-ui-detail">
            <table>
                <thead>
                    <tr>
                        <th width="25%">
                            {{ $t("customerLoanRequest.asset1") }}
                        </th>
                        <th width="25%">
                            {{
                                $t("customerLoanRequest.asset2")
                            }}
                        </th>
                        <th width="25%">
                            {{ $t("customerLoanRequest.asset3") }}
                        </th>
                        <th width="25%">
                            {{ $t("customerLoanRequest.asset4") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Input
                                v-model="model.name_loan_collateral_1"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.nameLoanCollateral1'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
													<Input
                                v-model="model.name_loan_collateral_2"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.nameLoanCollateral2'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
													<Input
                                v-model="model.name_loan_collateral_3"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.nameLoanCollateral3'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
													<Input
                                v-model="model.name_loan_collateral_4"
																:placeholder="
                                    $t(
                                        'customerLoanRequest.nameLoanCollateral4'
                                    )
                                "
                            >
                            </Input>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Input @on-change="onCalculatePriceLoanCollateral"
                                v-model.number="model.price_loan_collateral_1"
																type="number"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.priceLoanCollateral1'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
													<Input @on-change="onCalculatePriceLoanCollateral"
                                v-model.number="model.price_loan_collateral_2"
																type="number"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.priceLoanCollateral2'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
													<Input @on-change="onCalculatePriceLoanCollateral"
                                v-model.number="model.price_loan_collateral_3"
																type="number"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.priceLoanCollateral3'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
													<Input @on-change="onCalculatePriceLoanCollateral"
                                v-model.number="model.price_loan_collateral_4"
																type="number"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.priceLoanCollateral4'
                                    )
                                "
                            >
                            </Input>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Input
                                v-model="model.card_type_1"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.cardType1'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
													<Input
                                v-model="model.card_type_2"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.cardType2'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
													<Input
                                v-model="model.card_type_3"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.cardType3'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
													<Input
                                v-model="model.card_type_4"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.cardType4'
                                    )
                                "
                            >
                            </Input>
                        </td>
                    </tr>
                    <tr>
                        <td>
													<DatePicker
                              :value="model.date_issuance_1"
                              placeholder="DD-MM-YYYY"
                              format="dd-MM-yyyy"
															@on-change="onChangeDateIssuance1"
															class="tw-w-full"
                          ></DatePicker>
                        </td>
                        <td>
													<DatePicker
                              :value="model.date_issuance_2"
                              placeholder="DD-MM-YYYY"
                              format="dd-MM-yyyy"
															@on-change="onChangeDateIssuance2"
															class="tw-w-full"
                          ></DatePicker>
                        </td>
                        <td>
													<DatePicker
                              :value="model.date_issuance_3"
                              placeholder="DD-MM-YYYY"
                              format="dd-MM-yyyy"
															@on-change="onChangeDateIssuance3"
															class="tw-w-full"
                          ></DatePicker>
                        </td>
                        <td>
													<DatePicker
                              :value="model.date_issuance_4"
                              placeholder="DD-MM-YYYY"
                              format="dd-MM-yyyy"
															@on-change="onChangeDateIssuance4"
															class="tw-w-full"
                          ></DatePicker>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Input
                                v-model="model.issue_by_1"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.issuedBy1'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
													<Input
                                v-model="model.issue_by_2"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.issuedBy2'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
													<Input
                                v-model="model.issue_by_3"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.issuedBy3'
                                    )
                                "
                            >
                            </Input>
                        </td>
                        <td>
													<Input
                                v-model="model.issue_by_4"
                                :placeholder="
                                    $t(
                                        'customerLoanRequest.issuedBy4'
                                    )
                                "
                            >
                            </Input>
                        </td>
                    </tr>
                </tbody> 
								<thead>
                    <tr>
                        <th width="50%" colspan="2" class="tw-text-right text-success tw-font-bold">
													Price loan collateral compared to loans 130% (Price loan collateral ៖ Loan amount) x 100:
                        </th>
                        <th width="50%" colspan="2">
                            <Input
                                v-model="model.loan_collateral_amount"
                                style="width: 100%"
                                size="large"
                                disabled
                            >
                            </Input>
                        </th>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";

export default {
    name: "loanCollateral",
    props: ["value", "validation"],
    data() {
        return {
            model: {},
            errors: new Errors()
        };
    },
    created() {
        this.model = this.value;
    },
    methods: {
			onCalculatePriceLoanCollateral() {
            this.model.total_price_loan_collateral =
                (isNaN(parseFloat(this.model.price_loan_collateral_1))
                    ? 0
                    : parseFloat(this.model.price_loan_collateral_1)) +
                (isNaN(parseFloat(this.model.price_loan_collateral_2))
                    ? 0
                    : parseFloat(this.model.price_loan_collateral_2)) +
                (isNaN(parseFloat(this.model.price_loan_collateral_3))
                    ? 0
                    : parseFloat(this.model.price_loan_collateral_3)) +
                (isNaN(parseFloat(this.model.price_loan_collateral_4))
                    ? 0
                    : parseFloat(this.model.price_loan_collateral_4));
					this.model.loan_collateral_amount = parseFloat((this.model.total_price_loan_collateral / this.model.request_amount) * 100)
        },
				onChangeDateIssuance1(date) {
            this.model.date_issuance_1 = date;
        },
				onChangeDateIssuance2(date) {
            this.model.date_issuance_2 = date;
        },
				onChangeDateIssuance3(date) {
            this.model.date_issuance_3 = date;
        },
				onChangeDateIssuance4(date) {
            this.model.date_issuance_4 = date;
        },
    },
    watch: {
        validation: {
            handler() {
                this.errors = new Errors(this.validation.errors);
            },
            deep: true
        }
    }
};
</script>
