<template>
	<div>
			<form class="form-horizontal form-horizontal-text-right">
					<div class="row">
							<div class="col-md-6">
									<div class="row mb-3">
											<label
													class="form-label col-form-label col-md-4 tw-text-sm"
													>{{ $t("customerLoanRequest.ផ្ទះទំហំ") }}</label
											>
											<div class="col-md-7">
													<div
															class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
													>
															<Input
																			v-model="model.home_size"
																			style="width: 60%"
																	>
															</Input>
															<label
																	class="form-label col-form-label tw-text-sm"
															>{{
																			$t("customerLoanRequest.ធ្វើពី")
																	}}</label
															>
															<Input
																	v-model="model.make_of"
																	style="width: 60%"
															></Input>
													</div>
											</div>
									</div>
									<div class="row mb-3">
											<label
													class="form-label col-form-label col-md-4 tw-text-sm"
													>{{ $t("customerLoanRequest.ដីភូមិទំហំ") }}</label
											>
											<div class="col-md-7">
												<Input
													v-model="model.village_land_size"
																	>
												</Input>
											</div>
									</div>
									<div class="row mb-3">
											<label
													class="form-label col-form-label col-md-4 tw-text-sm"
													>{{ $t("customerLoanRequest.ដីស្រែទំហំ") }}</label
											>
											<div class="col-md-7">
												<Input
													v-model="model.farm_size"
																	>
												</Input>
											</div>
									</div>
									<div class="row mb-3">
											<label
													class="form-label col-form-label col-md-4 tw-text-sm"
													>{{ $t("customerLoanRequest.ដីចំការទំហំ") }}</label
											>
											<div class="col-md-7">
												<Input
													v-model="model.plantation_size"
																	>
												</Input>
											</div>
									</div>
							</div>

							<div class="col-md-4">
								<div class="row mb-3">
											<label
													class="form-label col-form-label col-md-4 tw-text-sm"
													></label
											>
											<div class="col-md-7">
													<div
															class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
													>
													<ts-checkbox
                                        v-model="model.is_chicken"
                                        class="form-label col-form-label tw-text-sm"                                        
                                        >{{
                                            $t("customerLoanRequest.មាន់")
                                        }}</ts-checkbox
                                    >
															<InputNumber type="number" :disabled="!model.is_chicken"
																			v-model.number="model.amount_chicken"
																			style="width: 60%" :min="0"
																	>
															</InputNumber>
															<ts-checkbox
																						v-model="model.is_duck"
																						class="form-label col-form-label tw-text-sm"
															>{{
																			$t("customerLoanRequest.ទា")
																	}}</ts-checkbox
															>
															<InputNumber type="number" :disabled="!model.is_duck"
																	v-model.number="model.amount_duck"
																	style="width: 60%" :min="0"
															></InputNumber>
													</div>
											</div>
								</div>
								<div class="row mb-3">
											<label
													class="form-label col-form-label col-md-4 tw-text-sm"
													></label
											>
											<div class="col-md-7">
													<div
															class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
													>
													<ts-checkbox
                                        v-model="model.is_geese"
                                        class="form-label col-form-label tw-text-sm"                                        
                                        >{{
                                            $t("customerLoanRequest.ក្ងាន")
                                        }}</ts-checkbox
                                    >
															<InputNumber type="number" :disabled="!model.is_geese"
																			v-model.number="model.amount_geese"
																			style="width: 60%" :min="0"
																	>
															</InputNumber>
															<ts-checkbox v-model="model.is_cow"
																	class="form-label col-form-label tw-text-sm"
															>{{
																			$t("customerLoanRequest.គោ")
																	}}</ts-checkbox
															>
															<InputNumber type="number" :disabled="!model.is_cow"
																	v-model.number="model.amount_cow"
																	style="width: 60%" :min="0"
															></InputNumber>
													</div>
											</div>
								</div>
								<div class="row mb-3">
											<label
													class="form-label col-form-label col-md-4 tw-text-sm"
													></label
											>
											<div class="col-md-7">
													<div
															class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
													>
													<ts-checkbox
                                        v-model="model.is_buffalo"
                                        class="form-label col-form-label tw-text-sm"                                        
                                        >{{
                                            $t("customerLoanRequest.ក្របី")
                                        }}</ts-checkbox
                                    >
															<InputNumber type="number" :disabled="!model.is_buffalo"
																			v-model.number="model.amount_buffalo"
																			style="width: 60%" :min="0"
																	>
															</InputNumber>
															<ts-checkbox v-model="model.is_pig"
																	class="form-label col-form-label tw-text-sm"
															>{{
																			$t("customerLoanRequest.ជ្រូក")
																	}}</ts-checkbox
															>
															<InputNumber type="number" :disabled="!model.is_pig"
																	v-model.number="model.amount_page"
																	style="width: 60%" :min="0"
															></InputNumber>
													</div>
											</div>
								</div>
								<div class="row mb-3">
											<label
													class="form-label col-form-label col-md-4 tw-text-sm"
													></label
											>
											<div class="col-md-7">
													<div
															class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
													>
													<ts-checkbox
                                        v-model="model.is_horse"
                                        class="form-label col-form-label tw-text-sm"                                        
                                        >{{
                                            $t("customerLoanRequest.សេះ")
                                        }}</ts-checkbox
                                    >
															<InputNumber type="number" :disabled="!model.is_horse"
																			v-model.number="model.amount_horse"
																			style="width: 60%" :min="0"
																	>
															</InputNumber>
															<ts-checkbox v-model="model.is_bicycle"
																	class="form-label col-form-label tw-text-sm"
															>{{
																			$t("customerLoanRequest.កង់")
																	}}</ts-checkbox
															>
															<InputNumber type="number" :disabled="!model.is_bicycle"
																	v-model.number="model.amount_bicycle"
																	style="width: 60%" :min="0"
															></InputNumber>
													</div>
											</div>
								</div>
								<div class="row mb-3">
											<label
													class="form-label col-form-label col-md-4 tw-text-sm"
													></label
											>
											<div class="col-md-7">
													<div
															class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
													>
													<ts-checkbox
                                        v-model="model.is_motorcycle"
                                        class="form-label col-form-label tw-text-sm"                                        
                                        >{{
                                            $t("customerLoanRequest.ម៉ូតូ")
                                        }}</ts-checkbox
                                    >
															<InputNumber type="number" :disabled="!model.is_motorcycle"
																			v-model.number="model.amount_motorcycle"
																			style="width: 66%" :min="0"
																	>
															</InputNumber>
															<ts-checkbox v-model="model.is_car"
																	class="form-label col-form-label tw-text-sm"
															>{{
																			$t("customerLoanRequest.ឡាន")
																	}}</ts-checkbox
															>
															<InputNumber type="number" :disabled="!model.is_car"
																	v-model.number="model.amount_car"
																	style="width: 60%" :min="0"
															></InputNumber>
													</div>
											</div>
								</div>
								<div class="row mb-3">
											<label
													class="form-label col-form-label col-md-4 tw-text-sm"
													></label
											>
											<div class="col-md-7">
													<div
															class="tw-flex tw-justify-between tw-space-x-1 tw-whitespace-nowrap"
													>
													<ts-checkbox
                                        v-model="model.is_bulldozer"
                                        class="form-label col-form-label tw-text-sm"                                        
                                        >{{
                                            $t("customerLoanRequest.គោយន្ត")
                                        }}</ts-checkbox
                                    >
															<InputNumber type="number" :disabled="!model.is_bulldozer"
																			v-model.number="model.amount_bulldozer"
																			style="width: 60%" :min="0"
																	>
															</InputNumber>
															<label style="width: 90%;"
															></label
															>
													</div>
											</div>
								</div>
							</div>
							<div class="col-md-1"></div>
					</div>
			</form>
	</div>
</template>

<script>
import { Errors } from "form-backend-validation";

export default {
	name: "customerAssets",
	props: ["value", "validation"],
	data() {
			return {
					model: {},
					errors: new Errors(),
					
			};
	},
	created() {
			this.model = this.value;
	},
	methods: {
	},
	watch: {
			validation: {
					handler() {
							this.errors = new Errors(this.validation.errors);
					},
					deep: true
			}	
	}
};
</script>
