import { render, staticRenderFns } from "./debts-owed-and-ability-to-borrow-money.vue?vue&type=template&id=052cccb5&"
import script from "./debts-owed-and-ability-to-borrow-money.vue?vue&type=script&lang=js&"
export * from "./debts-owed-and-ability-to-borrow-money.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports